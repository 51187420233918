import React from "react";
import { useState } from "react";

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleButton = (props: Props) => {
  const [checked, setChecked] = useState(props.checked);

  const clicked = () => {
    props.onChange(!checked);
    setChecked(!checked);
  };

  return (
    <div className="py-1">
      <label className='inline-flex relative items-center cursor-pointer'>
        <input type='checkbox' value='' className='sr-only peer' onChange={clicked} checked={checked} />
        <div className='w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-600'></div>
      </label>
    </div>
  );
};

export default ToggleButton;