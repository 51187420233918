interface Props {
  content: string;
}

const SlideText = (props: Props) => {
  return (
    <div className='mt-3 p-5 bg-slate-200 border rounded shadow-md justfiy-center'>
      <div className='text-left whitespace-pre-wrap'>
        {props.content}
      </div>
    </div>
  );
};

export default SlideText;