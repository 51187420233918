import _ from 'lodash';
import { useState } from 'react';
import { SlideOptionsData } from './model/SlideOptionsData';

export const SLIDE_SPLIT_SYMBOLS = [',', '.', ';', ':', '!', '?', '"', '«'];

interface Props {
  onUpdate: (e: SlideOptionsData) => void;
}

const DEFAULT_SLIDE_OPTIONS_DATA: SlideOptionsData = {
  upperCase: true,
  referenceAbove: true,
  charactersPerLine: 55,
  linesPerSlide: 4,
  newSlideAfter: [...SLIDE_SPLIT_SYMBOLS],
}

const SlideOptions = (props: Props) => {
  const [slideOptionsData, setSlideOptionsData] = useState(DEFAULT_SLIDE_OPTIONS_DATA);
  
  const stateModifier = (mutator: (o: SlideOptionsData) => void) => {
    const copy = _.cloneDeep(slideOptionsData);
    mutator(copy);
    setSlideOptionsData(copy);
    props.onUpdate(copy);
  };

  const upperCase = (e: boolean) => {
    stateModifier(s => s.upperCase = e);
  };

  const referenceAbove = (e: boolean) => {
    stateModifier(s => s.referenceAbove = e);
  };

  const charactersPerLineChange = (e: number) => {
    stateModifier(s => s.charactersPerLine = e);
  };

  const linesPerSlideChange = (e: number) => {
    stateModifier(s => s.linesPerSlide = e);
  };

  const toogleSlideSplitSymbole = (e: string) => {
    if (slideOptionsData.newSlideAfter.includes(e)) {
      const filtered = slideOptionsData.newSlideAfter.filter(s => s !== e);
      stateModifier(s => s.newSlideAfter = filtered);
    } else {
      stateModifier(s => s.newSlideAfter = [...slideOptionsData.newSlideAfter, e]);
    }
  };

  const symbols = SLIDE_SPLIT_SYMBOLS.map((e, i) => <div key={i} className='inline-flex ml-7 text-slate-700 items-center'>
    <input type='checkbox' id={'symbol-' + i} onChange={() => toogleSlideSplitSymbole(e)} checked={slideOptionsData.newSlideAfter.includes(e)} />
    <label className='ml-2' htmlFor={'symbol-' + i}>{e}</label>
  </div>);

  return (
    <div className='mt-5 p-5 border rounded'>
      <h2 className='text-xl mb-3 text-slate-900'>Options:</h2>
      <div className='ml-1'>
        <div className='inline-flex text-slate-700 items-center'>
          <input type='checkbox' id='upper-case' onChange={e => upperCase(e.currentTarget.checked)} checked={slideOptionsData.upperCase} />
          <label className='ml-2' htmlFor='flexCheckDefault'>Upper-case</label>
        </div>
        <div className='inline-flex ml-7 text-slate-700 items-center'>
          <input type='checkbox' id='reference-position' onChange={e => referenceAbove(e.currentTarget.checked)} checked={slideOptionsData.referenceAbove} />
          <label className='ml-2' htmlFor='reference-position'>Reference above</label>
        </div>
        <div className='inline-flex ml-7 text-slate-700 items-center'>
          <label className='ml-2 text-slate-700' htmlFor='characters-per-line'>Characters per line:</label>
          <input type='number' id='characters-per-line' className='w-[50px] ml-2 pl-1 text-slate-700 border rounded focus:outline-none' onChange={e => charactersPerLineChange(Number(e.currentTarget.value))} value={slideOptionsData.charactersPerLine} />
        </div>
        <div className='inline-flex ml-7 text-slate-700 items-center'>
          <label className='ml-2' htmlFor='lines-per-slide'>Lines per slide:</label>
          <input type='number' id='lines-per-slide' className='w-[50px] ml-2 pl-1 text-slate-700 border rounded focus:outline-none' onChange={e => linesPerSlideChange(Number(e.currentTarget.value))} value={slideOptionsData.linesPerSlide} />
        </div>
      </div>
      <div className='mt-3 ml-1 text-slate-700'>
        <label>New slide after:</label>
        {symbols}
      </div>
    </div>
  );
};

export default SlideOptions;