export interface CustomText {
  id: number;
  reference: string;
  text: string;
}

export interface Passage {
  id: number;
  book: string;
  chapter: string;
  verses: string;
  translation: string;
}

export type RowInput = CustomText | Passage;

export const isCustomText = (e: RowInput) => {
  return 'reference' in e && 'text' in e;
};

export const isPassage = (e: RowInput) => {
  return 'book' in e && 'chapter' in e && 'verses' in e && 'translation' in e;
};