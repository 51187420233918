import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { booksPromise, translationsPromise } from './data/DataStorage';
import { CustomText, isCustomText, Passage, RowInput } from './model/RowInput';
import { Translation } from './model/Translation';
import TableDropdown from './TableDropdown';
import TableTextInput from './TableTextInput';
import ToggleButton from './ToggleButton';

interface Props {
  first?: boolean;
  last?: boolean;
  rowInput?: RowInput;
  fixed: boolean;
  onComplete: (e: RowInput) => void;
  onUp: (id: number) => void;
  onDown: (id: number) => void;
  onDelete: (id: number) => void;
}

let idCounter = 0;

const PassageTableRow = (props: Props) => {
  const [allBooks, setAllBooks] = useState<string[]>([]);
  const [allTranslations, setAllTranslations] = useState<Translation[]>([]);
  const [custom, setCustom] = useState(props.rowInput !== undefined && isCustomText(props.rowInput));
  const [rowInput, setRowInput] = useState<RowInput>(props.rowInput ?? (custom ? { id: idCounter++, reference: 'Reference', text: 'Text' } : { id: idCounter++, book: '', chapter: '', verses: '', translation: '' }));

  const customTextModifier = (mutator: (o: CustomText) => void) => {
    const customText = rowInput as CustomText;
    const copy = { id: customText.id, reference: customText.reference, text: customText.text };
    mutator(copy);
    setRowInput(copy);
    if (isValidContent(copy)) {
      props.onComplete(copy);
    }
  };

  const passageModifier = (mutator: (o: Passage) => void) => {
    const passage = rowInput as Passage;
    const copy = { id: passage.id, book: passage.book, chapter: passage.chapter, verses: passage.verses, translation: passage.translation };
    mutator(copy);
    setRowInput(copy);
    if (isValidContent(copy)) {
      props.onComplete(copy);
    }
  };

  const isValidContent = (e: RowInput): boolean => {
    if (isCustomText(e)) {
      return true;
    }
    const p = e as Passage;
    return p.book !== '' && p.verses !== '' && p.translation !== '';
  };

  const onCustomToggle = (checked: boolean) => {
    if (checked) {
      setRowInput({ id: idCounter++, reference: 'Reference', text: 'Text' });
    } else {
      setRowInput({ id: idCounter++, book: '', chapter: '', verses: '', translation: '' });
    }
    setCustom(checked);
  };

  const customReferenceUpdate = (e: string) => {
    customTextModifier(c => c.reference = e);
  };

  const customTextUpdate = (e: string) => {
    customTextModifier(c => c.text = e);
  };

  const bookUpdate = (e: string) => {
    passageModifier(p => p.book = e);
  };

  const chapterUpdate = (e: string) => {
    passageModifier(p => p.chapter = e);
  }

  const versesUpdate = (e: string) => {
    passageModifier(p => p.verses = e);
  };

  const translationUpdate = (e: string) => {
    passageModifier(p => p.translation = e);
  };

  booksPromise.then(e => setAllBooks(e));
  translationsPromise.then(e => setAllTranslations(e));

  return (
    <div className='flex border-t'>
      {
        !props.fixed ?
          <div className='w-1/6 pl-6'>
            <ToggleButton checked={custom} onChange={onCustomToggle}></ToggleButton>
          </div>
          :
          <div className='w-1/6 pl-8 pt-1'>
            {
              custom ?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(51,65,85)" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

                :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(51,65,85)" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
            }
          </div>
      }
      {
        custom ?
          <div className='w-2/6 pr-10'>
            <TableTextInput text={(rowInput as CustomText).reference} regex='^.*$' fixed={props.fixed} onTextAvailable={customReferenceUpdate} />
          </div>
          :
          <div className='w-1/6 pr-10'>
            <TableDropdown selection={(rowInput as Passage).book} entires={allBooks} onSelection={bookUpdate} />
          </div>
      }
      {
        custom ?
          <div className='w-2/6 pr-10'>
            <TableTextInput text={(rowInput as CustomText).text} regex='^.*$' fixed={props.fixed} onTextAvailable={customTextUpdate} />
          </div>
          :
          <div className='w-1/6 pr-10'>
            <TableTextInput text={(rowInput as Passage).chapter} regex='^[1-9]?[0-9]{0,2}$' fixed={props.fixed} onTextAvailable={chapterUpdate} />
          </div>
      }
      {
        custom ?
          null
          :
          <div className='w-1/6 pr-10'>
            <TableTextInput text={(rowInput as Passage).verses} regex='^[1-9][0-9]{0,2}?(-[1-9][0-9]{0,2}?)?$' fixed={props.fixed} onTextAvailable={versesUpdate} />
          </div>
      }
      {
        custom ?
          null
          :
          <div className='w-1/6 pr-10'>
            <TableDropdown selection={(rowInput as Passage).translation} entires={allTranslations.map(e => e.full_name + ' (' + e.short_name + ')')} onSelection={translationUpdate} />
          </div>
      }
      {
        props.fixed ?
          <div className='w-1/6 flex'>
            <div className='flex-grow'></div>
            {!props.first ? <button className='w-[36px] pb-1 text-red-700 text-xl font-bold text-sky-600 hover:text-sky-700 rounded' onClick={() => props.onUp(rowInput.id)}>{'\u2191'}</button> : <div className='inline-block w-[36px]'></div>}
            {!props.last ? <button className='w-[36px] pb-1 text-red-700 text-xl font-bold text-sky-600 hover:text-sky-700 rounded' onClick={() => props.onDown(rowInput.id)}>{'\u2193'}</button> : <div className='inline-block w-[36px]'></div>}
            <button className='w-[36px] pb-1 text-red-700 text-xl font-bold text-red-600 hover:text-red-700 rounded' onClick={() => props.onDelete(rowInput.id)}>{'\u00D7'}</button>
            <div className='flex-grow'></div>
          </div>
          :
          <div className='w-1/6'></div>
      }
    </div>
  );
};

export default PassageTableRow;