import { useState } from 'react';
import { retreivePassageText } from './data/DataLoader';
import { atBooksPromise, translationsPromise } from './data/DataStorage';
import Footer from './Footer';
import Header from './Header';
import InfoModal, { InfoModalType } from './InfoModal';
import { CustomText, isCustomText, isPassage, Passage, RowInput } from './model/RowInput';
import { OutputElement } from './model/OutputElement';
import { SlideOptionsData } from './model/SlideOptionsData';
import { Translation } from './model/Translation';
import PassageTable from './PassageTable';
import SlideOptions, { SLIDE_SPLIT_SYMBOLS } from './SlideOptions';
import SlideResults from './SlideResults';

const App = () => {

  const defaultSlideOptionsData: SlideOptionsData = {
    upperCase: true,
    referenceAbove: true,
    charactersPerLine: 55,
    linesPerSlide: 4,
    newSlideAfter: [...SLIDE_SPLIT_SYMBOLS],
  };

  const [outputElements, setOutputElements] = useState<OutputElement[]>([]);
  const [slideOptionsData, setSlideOptionsData] = useState(defaultSlideOptionsData);
  const [atBooks, setAtBooks] = useState<string[]>([])
  const [allTranslations, setAllTranslations] = useState<Translation[]>([]);
  const [hidden, setHidden] = useState(true);

  atBooksPromise.then(e => setAtBooks(e));
  translationsPromise.then(e => setAllTranslations(e));

  const retrieveTexts = (rowInputs: RowInput[]) => {
    for (let e of rowInputs) {
      if (isPassage(e) && !isValidContent(e as Passage)) {
        setHidden(false);
        return;
      }
    }
    const promises = rowInputs.map(e => isCustomText(e) ? e : retreivePassageText(e as Passage));
    Promise.all(promises).then(texts => {
      const oe = texts.map((e, i) => typeof e === 'string' ? ({ passage: rowInputs[i] as Passage, text: e }) : (e as CustomText));
      setOutputElements(oe);
    });
  };

  const isValidContent = (e: Passage) => {
    const entryShortName = e.translation.split('(')[1].replace(')', '');
    const translation = allTranslations.find(e => e.short_name === entryShortName);
    if (translation === undefined) {
      return false;
    }
    if (atBooks.includes(e.book)) {
      if (!translation.parts.includes('AT')) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <div className='relative min-h-screen'>
        <div className='pb-20'>
          <Header />
          <div className='container m-auto'>
            <PassageTable onUpdate={retrieveTexts} />
            <SlideOptions onUpdate={e => setSlideOptionsData(e)} />
            {outputElements.length > 0 ? <SlideResults outputElements={outputElements} slideOptionsData={slideOptionsData} /> : null}
          </div>
        </div>
        <Footer />
      </div>
      <InfoModal hidden={hidden} content={'One of the selected translations has no Old Testament available!\nCheck your NGÜ and DBU entries.'} type={InfoModalType.Error} onClose={() => setHidden(true)}></InfoModal>
    </div>
  );
};

export default App;
