import { useState } from 'react';

interface Props {
  selection: string;
  entires: string[];
  onSelection: (e: string) => void;
}

enum Mode {
  Normal,
  Expanded,
  Fixed,
}

const TableDropdown = (props: Props) => {
  const [mode, setMode] = useState(props.selection === '' ? Mode.Normal : Mode.Fixed);
  const [selectedEntry, setSelectedEntry] = useState(props.selection);
  const [stringFilter, setStringFilter] = useState('');

  const openDropdown = () => setMode(Mode.Expanded);
  
  const selectEntry = (e: string) => {
    setMode(Mode.Fixed);
    setSelectedEntry(e);
    props.onSelection(e);
  };

  const filterDropdown = (e: string) => setStringFilter(e);
  
  const editSelection = () => {
    setMode(Mode.Expanded);
  };

  const entries = props.entires.filter(e => e.toUpperCase().indexOf(stringFilter.toUpperCase()) !== -1);

  if (mode === Mode.Fixed) {
    return (<button className='p-1 text-left text-gray-600 hover:underline' onClick={editSelection}>{selectedEntry}</button>);
  }

  return (
    <div className='relative'>
      <button onClick={openDropdown} className={'w-full mt-1 px-2 bg-sky-600 hover:bg-sky-700 active:bg-sky-800 text-white rounded'}>
        <div className='container flex'>
          <div className='inline text-left'>{'\u25BC'}</div>
          <div className='inline-block w-px ml-2 bg-white'></div>
          <div className='inline ml-2 text-left'>{selectedEntry === '' ? '-' : selectedEntry}</div>
        </div>
      </button>
      {
        mode === Mode.Expanded ?
          <div className='absolute top-7 z-10 bg-gray-50 border border-slate-300 rounded'>
            <input type='text' placeholder='Search...' className={'w-full pl-1 text-gray-700 border border-slate-300 rounded focus:outline-none'}
              onKeyUp={e => filterDropdown(e.currentTarget.value)} autoComplete='off' />
            <div className='max-h-60 overflow-y-auto'>
              {entries.map((e, i) => <button key={i} className={'block w-full pl-1 text-left text-gray-600 hover:underline cursor-pointer'} onClick={() => selectEntry(e)}>{e}</button>)}
            </div>
          </div>
          :
          null
      }
    </div>
  );
};

export default TableDropdown;