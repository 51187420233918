export enum InfoModalType {
  Info,
  Warning,
  Error
}

export interface Props {
  hidden: boolean,
  content: string,
  type: InfoModalType,
  onClose: () => void;
}

const InfoModal = (props: Props) => {

  const closeModal = () => {
    props.onClose();
  };

  const determineBgColor = () => {
    if (props.type === InfoModalType.Info) {
      return 'bg-white';
    }
    if (props.type === InfoModalType.Warning) {
      return 'bg-cyan-100';
    }
    if (props.type === InfoModalType.Error) {
      return 'bg-red-100';
    }
    return 'bg-white';
  }

  if (props.hidden) {
    return null;
  }

  return (
    <div className='overflow-y-auto overflow-x-hidden fixed inset-x-0 inset-y-0 z-50 w-full h-full bg-white/[.75]'>
      <div className='relative top-2/4 left-2/4 max-w-2xl translate-x-[-50%] translate-y-[-50%]'>
        <div className={'relative ' + determineBgColor() + ' rounded shadow border border-slate-300'}>
          <div className='flex justify-between items-start p-4 rounded-t border-b'>
            <div className='p-1 text-l text-slate-700 whitespace-pre-wrap'>
              {props.content}
            </div>
            <button type='button' className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center' onClick={closeModal}>
              <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd'></path></svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;