const Footer = () => {
  return (
    <div className='absolute bottom-0 w-full h-16'>
      <div className='flex h-full p-4 justify-center bg-slate-100'>
        <div className='container flex'>
          <div className='w-1/3 flex'>
            <div className='flex-grow'></div>
            <span className='text-gray-600'>© Frederik Dennig. All rights reserved.</span>
            <div className='flex-grow'></div>
          </div>
          <div className='w-1/3 flex'>
            <div className='flex-grow'></div>
            <div>
              <a className='p-1 text-gray-600 hover:underline' href='http://tv.frederikdennig.com/legal-notice-en.html'>Legal Notice</a>
              <span className='text-gray-600'>-</span>
              <a className='p-1 text-gray-600 hover:underline' href='http://tv.frederikdennig.com/legal-notice-de.html'>Impressum</a>
              <div className='flex-grow'></div>
            </div>
            <div className='flex-grow'></div>
          </div>
          <div className='w-1/3 flex'>
            <div className='flex-grow'></div>
            <div>
              <a className='p-1 text-gray-600 hover:underline' href='http://tv.frederikdennig.com/data-protection-en.html'>Data Protection</a>
              <span className='text-gray-600'>-</span>
              <a className='p-1 text-gray-600 hover:underline' href='http://tv.frederikdennig.com/data-protection-de.html'>Datenschutz</a>
            </div>
            <div className='flex-grow'></div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;