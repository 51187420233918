import { useState } from 'react';
import { RowInput } from './model/RowInput';
import { Passage } from './model/RowInput';
import PassageTableRow from './PassageTableRow';

interface Props {
  onUpdate: (e: RowInput[]) => void;
}

const PassageTable = (props: Props) => {
  const [rowInputs, setRowInputs] = useState<RowInput[]>([]);

  const storeRowInput = (e: RowInput) => {
    const idx = rowInputs.findIndex(p => p.id === e.id);
    if (idx === -1) {
      const newRowInput = [...rowInputs, e];
      setRowInputs(newRowInput);
      props.onUpdate(newRowInput);
    } else {
      rowInputs[idx] = e;
      setRowInputs([...rowInputs]);
      props.onUpdate(rowInputs);
    }
  };
  
  const rowUp = (id: number) => {
    const idx = rowInputs.findIndex(e => e.id === id);
    if (idx < 1) {
      return;
    }
    const e = rowInputs[idx];
    rowInputs[idx] = rowInputs[idx - 1];
    rowInputs[idx - 1] = e;
    setRowInputs([...rowInputs]);
    props.onUpdate([...rowInputs]);
  };
  
  const rowDown = (id: number) => {
    const idx = rowInputs.findIndex(e => e.id === id);
    if (idx > rowInputs.length - 2) {
      return;
    }
    const e = rowInputs[idx];
    rowInputs[idx] = rowInputs[idx + 1];
    rowInputs[idx + 1] = e;
    setRowInputs([...rowInputs]);
    props.onUpdate([...rowInputs]);
  };

  const deleteRow = (id: number) => {
    const filtered = rowInputs.filter(e => e.id !== id);
    setRowInputs(filtered);
    props.onUpdate(filtered);
  };
  
  const rows = rowInputs.map((e, i) => <PassageTableRow key={e.id} first={i === 0} last={i === rowInputs.length - 1} rowInput={e} fixed={true} onComplete={storeRowInput} onUp={rowUp} onDown={rowDown} onDelete={deleteRow} />);
  rows.push(<PassageTableRow key={rowInputs.length + 1} fixed={false} onComplete={storeRowInput} onUp={rowUp} onDown={rowDown} onDelete={deleteRow} />)
  
  return (
    <div className='mt-5 p-5 border rounded'>
      <div className='flex'>
        <div className='w-1/6 font-bold text-slate-700'>Custom Text</div>
        <div className='w-1/6 font-bold text-slate-700'>Book</div>
        <div className='w-1/6 font-bold text-slate-700'>Chapter</div>
        <div className='w-1/6 font-bold text-slate-700'>Verses</div>
        <div className='w-1/6 font-bold text-slate-700'>Translation</div>
        <div className='w-1/6 font-bold text-slate-700'></div>
      </div>
      <div className='mt-1'>
        {rows}
      </div>
    </div>
  );
};

export default PassageTable;