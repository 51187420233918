import { CustomText, Passage } from './RowInput';

export interface PassageText {
  passage: Passage;
  text: string;
}

export type OutputElement = CustomText | PassageText;

export const isPassageText = (e: OutputElement) => {
  return 'passage' in e;
};

export const isCustomText = (e: OutputElement) => {
  return 'reference' in e && 'text' in e;
};