import { CustomText, isCustomText, Passage } from './model/RowInput';

interface Props {
  label: string;
}

const PassageSeparator = (props: Props) => {
  return (
    <div className="flex mt-3 items-center">
      <div className="flex-grow border-t border-slate-400"></div>
      <span className="flex-shrink mx-5 text-slate-400">{props.label}</span>
      <div className="flex-grow border-t border-slate-400"></div>
    </div>
  );
};

export default PassageSeparator;